import React from "react";
import Service from "templates/service";
import Heading from "components/core/Typography/Heading";
import { useTranslation } from "react-i18next";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PINTEREST_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "constants/social";

export interface CookiesProps {
  location: Location;
}

export default ({ location }: CookiesProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={t("pages.cookies.title")}
      subtitle={t("pages.cookies.subtitle")}
      seoProps={{
        title: t("pages.cookies.seo.title"),
        description: t("pages.cookies.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-cookie-policy.jpg",
          alt: "Customerly Cookie Policy",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              FACEBOOK_URL,
              INSTAGRAM_URL,
              LINKEDIN_URL,
              PINTEREST_URL,
              TWITTER_URL,
              YOUTUBE_URL,
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <p>
        We and selected partners use cookies or similar technologies as
        specified in the{" "}
        <a href="https://www.iubenda.com/privacy-policy/45634728/cookie-policy?an=no&s_ck=false&newmarkup=yes">
          Cookie Policy
        </a>
        . With respect to advertising, we and selected third parties, may use
        precise geolocation data and actively scan device characteristics for
        identification in order to store and/or access information on a device
        and process personal data (e.g. browsing data, IP addresses, usage data
        or unique identifiers) for the following purposes: personalised ads and
        content, ad and content measurement, and audience insights; develop and
        improve products. You can freely give, deny, or withdraw your consent at
        any time by accessing the advertising preferences panel.
      </p>
      <Heading level={2}>What are cookies?</Heading>
      <p>
        Cookies are small files which are stored on a user's computer. They are
        designed to hold a modest amount of data specific to a particular client
        and website, and can be accessed either by the web server or the client
        computer. This allows the server to deliver a page tailored to a
        particular user, or the page itself can contain some script which is
        aware of the data in the cookie and so is able to carry information from
        one visit to the website (or related site) to the next.
      </p>
      <Heading level={2}>Why do we use cookies?</Heading>
      <p>
        We use our own and third party cookies for several reasons. Some cookies
        are required for technical reasons in order for our Websites and/or
        Services to operate, and we refer to these as "essential" cookies. Other
        cookies also enable us to track and target the interests of our users to
        enhance the experience on our Websites and/or Services. Third parties
        serve cookies through our Websites and/or Services for analytics and
        other purposes. This is described in more detail below.
      </p>
    </Service>
  );
};
